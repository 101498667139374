import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../Admin/axiosInstance'; 
import {
    Container,
    TextField,
    Button,
    Typography,
    Grid,
    Box,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    FormHelperText,
    IconButton,
    FormGroup, 
    FormControlLabel, 
    CircularProgress,
    Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const busRoutes = [
    {
        id: 1,
        name: 'Nairobi to Wajir',
        direction: 1, // outbound
        route: [
            'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa',
            'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis',
            'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
        ]
    },
    {
        id: 2,
        name: 'Wajir to Nairobi',
        direction: 2, // inbound
        route: [
            'Wajir', 'Laheley', 'Lag boqol', 'Qanchara', 'Habaswein', 'Madogashe', 'Dujis',
            'Shimbirey', 'Garissa', 'Madogo', 'Tula', 'Bangal', 'Katumba', 'Bisan hargeysa',
            'Ukasi', 'Mwingi', 'Kathiyoko', 'Matuu', 'Thika', 'Nairobi'
        ]
    }
];

const ParcelBooking = ({ onClose }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        senderName: '',
        senderPhone: '',
        receiverName: '',
        receiverPhone: '',
        recipientAddress: '',
        parcelContent: '',
        packageType: '',
        source: '',
        destination: '',
        modeOfPayment: '',
        parcelWeight: '', 
        itemsCount: '', // New field
        status: 'INPROGRESS',
        direction: '',
        mpesaNumber: '', // Mpesa specific field
        airtelNumber: '', // Airtel specific field
        mpesaAmount: 0, 
        cashAmount: 0,
        fragile: false,
        highValue: false,
        electronics: false,
        perishable: false,
        urgent: false,
        valueInKsh: '',
        serialNumber: '',
        corporateName: '',
        otp: ''
    });
    const [otpSent, setOtpSent] = useState(false); // To track if OTP is sent
    const [otpVerified, setOtpVerified] = useState(false); // To track OTP verification
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [paymentPending, setPaymentPending] = useState(false);
    const [parcelId, setParcelId] = useState(null);
    const [otpRequested, setOtpRequested] = useState(false);
    const [trackingCode, setTrackingCode] = useState('');
    const [corporateSuggestions, setCorporateSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    // Fetch customer data from the API when the component mounts
    useEffect(() => {
      axiosInstance
      .get('/customer/findall') 
      .then((response) => {
        console.log(response.data); 
        setCorporateSuggestions(response.data); 
      })
      .catch((error) => {
        console.error("Error fetching corporate names: ", error);
      });
   }, []);

    useEffect(() => {
      if (formData.modeOfPayment === 'foc') {
        requestOtp(); // Automatically trigger OTP request for FOC payment
      }
    }, [formData.modeOfPayment]);
    
    

    const getDirection = (source, destination) => {
        const locations = [
            'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa',
            'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis', 
            'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
        ];
        
        const sourceIndex = locations.indexOf(source);
        const destinationIndex = locations.indexOf(destination);
    
        if (sourceIndex < destinationIndex) {
            return `${source} - ${destination}`;
        } else {
            return `${destination} - ${source}`;
        }
    };

    const handleChangeB = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        setFormData(prevState => ({
            ...prevState,
            [name]: fieldValue
        }));

         // If FOC is selected, automatically set price to 0
        if (name === 'modeOfPayment' && value === 'foc') {
            setFormData((prevState) => ({
                ...prevState,
                mpesaAmount: 0,
                cashAmount: 0 // Set price to 0 for FOC
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedFormData = { ...formData, [name]: value };
    
        // Determine the direction based on the source and destination
        if (name === 'source' || name === 'destination') {
            const { source, destination } = updatedFormData;
    
            let direction = '';
    
            if (source && destination) {
                // Check the outbound route (Nairobi -> Wajir)
                if (busRoutes[0].route.includes(source) && busRoutes[0].route.includes(destination)) {
                    const sourceIndex = busRoutes[0].route.indexOf(source);
                    const destinationIndex = busRoutes[0].route.indexOf(destination);
    
                    if (sourceIndex < destinationIndex) {
                        direction = busRoutes[0].direction; // Set direction to 1 for outbound
                    }
                }
    
                // Check the inbound route (Wajir -> Nairobi)
                if (!direction && busRoutes[1].route.includes(source) && busRoutes[1].route.includes(destination)) {
                    const sourceIndex = busRoutes[1].route.indexOf(source);
                    const destinationIndex = busRoutes[1].route.indexOf(destination);
    
                    if (sourceIndex < destinationIndex) {
                        direction = busRoutes[1].direction; // Set direction to 2 for inbound
                    }
                }
            }
    
            updatedFormData = { ...updatedFormData, direction };
        }
        // Payment Amounts Logic
    if (name === 'modeOfPayment') {
      const cashAmount = updatedFormData.cashAmount;
      const mpesaAmount = updatedFormData.mpesaAmount;

      switch (value) {
          case 'foc':
              updatedFormData = { 
                  ...updatedFormData, 
                  cashAmount: 0, 
                  mpesaAmount: 0 
              };
              break;
          case 'cash':
              updatedFormData = { 
                  ...updatedFormData, 
                  cashAmount: formData.cashAmount || 0, 
                  mpesaAmount: 0 
              };
              break;
          case 'Mpesa':
              updatedFormData = { 
                  ...updatedFormData, 
                  cashAmount: 0, // set Cash amount to 0
                  mpesaAmount: formData.mpesaAmount || 0
              };
              break;
            case 'cash + mpesa':
              const totalAmount = (formData.cashAmount || 0) + (formData.mpesaAmount || 0); // Calculate total amount
              updatedFormData = { 
                  ...updatedFormData, 
                  cashAmount: totalAmount - (formData.mpesaAmount || 0), 
                  mpesaAmount: formData.mpesaAmount || 0 
              };
              break;
              case 'cod':
              updatedFormData = { 
                  ...updatedFormData, 
                  cashAmount: formData.cashAmount || 0, 
                  mpesaAmount: 0 
              };
              break;
          default:
              break;
      }
  }
  
      setFormData(updatedFormData);
  };

  // Function to handle input changes
  const handleChangeC = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Trigger corporate name search when typing in corporate name field
    if (name === 'corporateName' && value) {
      fetchCorporateSuggestions(value);
    }
  };

  // Function to fetch corporate name suggestions
  const fetchCorporateSuggestions = async (query) => {
    try {
      const response = await axiosInstance.get(`/customer/search/${query}`);
      setCorporateSuggestions(response.data); 
    } catch (error) {
      console.error('Error fetching corporate names:', error);
    }
  };

  // Handle dropdown change
  const handleDropdownChange = (event) => {
    setFormData({ ...formData, corporateName: event.target.value });
  };


    const handlePaymentMethodChange = (e) => {
        const selectedMethod = e.target.value;
        setFormData(prevState => ({
            ...prevState,
            modeOfPayment: selectedMethod,
            cashAmount: selectedMethod === 'foc' ? 0 : prevState.cashAmount// Set price to 0 for FOC
        }));
    };
    
    const validateForm = () => {
        const newErrors = {};
        const phoneRegex = /^(1|7)\d{8}$/;

        if (!formData.senderName) {
            newErrors.senderName = 'Sender name is required';
        }
    
        if (!formData.senderPhone) {
            newErrors.senderPhone = 'Sender phone is required';
        } else if (!phoneRegex.test(formData.senderPhone)) {
            newErrors.senderPhone = 'Sender phone must start with 1 or 7 and be followed by 9 digits';
        }
    
        if (!formData.receiverName) {
            newErrors.receiverName = 'Recipient name is required';
        }
    
        if (!formData.receiverPhone) {
            newErrors.receiverPhone = 'Recipient phone is required';
        } else if (!phoneRegex.test(formData.receiverPhone)) {
            newErrors.receiverPhone = 'Recipient phone must start with 1 or 7 and be followed by 8 digits';
        }
        if (!formData.recipientAddress) newErrors.recipientAddress = 'Recipient address is required';
        if (!formData.parcelContent) newErrors.parcelContent = 'Parcel content is required';
        // if (formData.modeOfPayment !== 'foc' && !formData.price) {
        //     newErrors.price = 'Price is required';
        // }
        if (!formData.source) newErrors.source = 'Origin is required';
        if (!formData.destination) newErrors.destination = 'Destination is required';
        if (!formData.modeOfPayment) newErrors.modeOfPayment = 'Mode of payment is required';
        if (!formData.parcelWeight) newErrors.parcelWeight = 'Parcel weight is required'; // Existing validation
        if (!formData.itemsCount) newErrors.itemsCount = 'Items count is required'; // New validation
        return newErrors;
    };

    // Function to send OTP
    const sendOtp = async () => {
        try {
            const response = await axiosInstance.post(`/otp/add/${formData.senderPhone}`);
            setOtpSent(true);
            setSuccessMessage("OTP sent to your phone!");
        } catch (error) {
            setErrorMessage("Failed to send OTP.");
        }
    };

  
    // Handle OTP request
    const requestOtp = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.post('/otp/foc');
        if (response.status === 200) {
          setOtpRequested(true);
          setSuccessMessage('OTP sent successfully!');
        }
      } catch (error) {
        setErrorMessage('Failed to send OTP. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };


  // Verify OTP
    const verifyOtp = async () => {
      try {
        const response = await axiosInstance.get(`/otp/verify/${formData.otp}`);
        if (response.data === true) {
          setOtpVerified(true); // OTP verified successfully
          setSuccessMessage('OTP verified successfully!');
          setErrorMessage(''); 
        } else {
          setOtpVerified(false); 
          setSuccessMessage('');
          setErrorMessage('Invalid OTP.');
        }
      } catch (error) {
        setOtpVerified(false); 
        setSuccessMessage('');
        setErrorMessage('Failed to verify OTP.');
      }
    };



    const generateTrackingCode = () => {
        const characters = '0123456789'; 
        let trackingCode = ''; 
        for (let i = 0; i < 7; i++) {
            trackingCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return trackingCode;
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      };
      

      const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        setIsLoading(true); // Set loading state to true
        // Generate a tracking code
        const generatedTrackingCode = generateTrackingCode();
    
        const dataToSend = {
            ...formData,
            trackingCode: generatedTrackingCode,
            cashAmount: formData.modeOfPayment === 'foc' ? 0 : parseFloat(formData.cashAmount),
            mpesaAmount: formData.modeOfPayment === 'foc' ? 0 : parseFloat(formData.mpesaAmount),
        };
    
        try {
            const response = await axiosInstance.post('/parcel/add', dataToSend);
            setTrackingCode(generatedTrackingCode);
            setParcelId(response.data.id);
            setErrorMessage('');
    
            // Check for Mpesa Payment Mode
            if (formData.modeOfPayment === 'Mpesa' || formData.modeOfPayment === 'cash + mpesa') {
                setPaymentPending(true);
                checkPaymentStatus(generatedTrackingCode); // Check payment status
            } else {
                setSuccessMessage('Parcel registered successfully');
                // Navigate to receipt for other payment methods
                onClose(); // Close the form
                navigate('/receipt', { state: { trackingCode: generatedTrackingCode, ...formData } });
            }
        } catch (error) {
            setErrorMessage('There was an error registering the parcel.');
            setSuccessMessage('');
        } finally {
            setIsLoading(false); // Reset loading state
        }
    };
    

    const checkPaymentStatus = async (trackingCode) => {
      try {
          let attempts = 0; // Counter for number of attempts
          const intervalId = setInterval(async () => {
              attempts += 1;
              const response = await axiosInstance.get(`/parcel/paidstatus/${trackingCode}`);
              
              // Check if payment is successful
              if (response.data === true) {
                  clearInterval(intervalId); 
                  setPaymentPending(false);
                  setSuccessMessage("Payment successful!");
                  navigate('/receipt', { state: { trackingCode, ...formData } });
              } 
              // Check if attempts have reached the limit
              else if (attempts >= 5) {
                  clearInterval(intervalId); // Stop checking after 5 attempts
                  setPaymentPending(false);
                  setErrorMessage("Payment failed after multiple attempts. Please try again.");
              }
          }, 5000); // Check every 10 seconds
      } catch (error) {
          setErrorMessage('Error checking payment status.');
      }
  };
  

    const handleResendStkPush = async () => {
      try {
          await axiosInstance.post(`/parcel/stkpush/resend?parcelId=${parcelId}`);
          setSuccessMessage('STK Push Resent! Please complete the payment on your phone.');
      } catch (error) {
          setErrorMessage('Error resending STK Push.');
      }
  };
  
  

    const locations = [
        'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Ukasi', 'Bisan hargeysa',
        'Katumba', 'Bangal', 'Tula', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis', 
        'Madogashe', 'Habaswein', 'Qanchara', 'Lag boqol', 'Laheley', 'Wajir'
    ];

    const paymentOptions = [
        { id: 'Cash', label: 'Cash' },
        { id: 'Mpesa', label: 'Mpesa' },
        { id: 'cash + mpesa', label: 'Mpesa and Cash' },
        { id: 'corporate', label: 'Corporate'}, 
        { id: 'foc', label: 'FOC' }, 
        { id: 'cod', label: 'COD' }, 
        { id: 'AirtelMoney', label: 'Airtel Money' }
    ];
    
    return (
      <Container maxWidth="sm" sx={{ mt: { xs: 10, sm: 4 } }}> 
        <Box sx={{ mt: { xs: 2, sm: 4 } }}>
            <IconButton onClick={onClose} sx={{ float: 'right' }}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" component="h1" gutterBottom>
              Parcel Acceptance
            </Typography>
            {successMessage && <Typography color="primary">{successMessage}</Typography>}
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}

            {/* Show Payment Status Check for Mpesa */}
            {paymentPending ? (
              <Box mt={4} textAlign="center">
                <Typography variant="h6">Awaiting Mpesa Payment Confirmation...</Typography>
                <CircularProgress />
                <Box mt={2}>
                  <Button onClick={handleResendStkPush} variant="contained" color="secondary">
                    Resend STK Push
                  </Button>
                </Box>
              </Box>
            ) : (
              
              <>
  
            {/* {!otpSent && (
              <form onSubmit={(e) => { e.preventDefault(); sendOtp(); }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Sender Name"
                      name="senderName"
                      value={formData.senderName}
                      onChange={handleChange}
                      error={!!errors.senderName}
                      helperText={errors.senderName}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Sender Phone"
                      name="senderPhone"
                      value={formData.senderPhone}
                      onChange={handleChange}
                      error={!!errors.senderPhone}
                      helperText={errors.senderPhone}
                      required
                      placeholder="Enter 9 digits starting with 7 or 1"
                    />
                  </Grid>
                </Grid>
                <Button variant="contained" color="primary" type="submit">
                  Send OTP
                </Button>
              </form>
            )}
    
            {otpSent && !otpVerified && (
              <form onSubmit={(e) => { e.preventDefault(); verifyOtp(); }}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Enter OTP"
                      name="otp"
                      value={formData.otp}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                </Grid>
                <Button variant="contained" color="primary" type="submit">
                  Verify OTP
                </Button>
              </form>
            )} */}
    
            {/* {otpVerified && ( */}
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Sender Name"
                      name="senderName"
                      value={formData.senderName}
                      onChange={handleChange}
                      error={!!errors.senderName}
                      helperText={errors.senderName}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Sender Phone"
                      name="senderPhone"
                      value={formData.senderPhone}
                      onChange={handleChange}
                      error={!!errors.senderPhone}
                      helperText={errors.senderPhone}
                      required
                      placeholder="Enter 9 digits starting with 7 or 1"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Recipient Name"
                      name="receiverName"
                      value={formData.receiverName}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Recipient Phone"
                      name="receiverPhone"
                      value={formData.receiverPhone}
                      onChange={handleChange}
                      placeholder="Enter 9 digits starting with 7 or 1"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Recipient Address"
                      name="recipientAddress"
                      value={formData.recipientAddress}
                      onChange={handleChange}
                      error={!!errors.recipientAddress}
                      helperText={errors.recipientAddress}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Items Count"
                      name="itemsCount"
                      type="number"
                      value={formData.itemsCount}
                      onChange={handleChange}
                      error={!!errors.itemsCount}
                      helperText={errors.itemsCount}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Parcel Content"
                      name="parcelContent"
                      value={formData.parcelContent}
                      onChange={handleChange}
                      error={!!errors.parcelContent}
                      helperText={errors.parcelContent}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Packaging"
                      name="packageType"
                      value={formData.packageType}
                      onChange={handleChange}
                      error={!!errors.packageType}
                      helperText={errors.packageType}
                      required
                    />
                  </Grid>
    
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required error={!!errors.source}>
                      <InputLabel id="source-label">Origin</InputLabel>
                      <Select
                        labelId="source-label"
                        name="source"
                        value={formData.source}
                        onChange={handleChange}
                        label="Origin"
                      >
                        {locations.map((location, index) => (
                          <MenuItem key={index} value={location}>
                            {location}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.source && <FormHelperText>{errors.source}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required error={!!errors.destination}>
                      <InputLabel id="destination-label">Destination</InputLabel>
                      <Select
                        labelId="destination-label"
                        name="destination"
                        value={formData.destination}
                        onChange={handleChange}
                        label="Destination"
                      >
                        {locations.map((location, index) => (
                          <MenuItem key={index} value={location}>
                            {location}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.destination && <FormHelperText>{errors.destination}</FormHelperText>}
                    </FormControl>
                  </Grid>
    
                  <Grid item xs={12}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.fragile}
                            onChange={handleChangeB}
                            name="fragile"
                          />
                        }
                        label="FRAGILE"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.highValue}
                            onChange={handleChangeB}
                            name="highValue"
                          />
                        }
                        label="HIGH VALUE"
                      />
                      {formData.highValue && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Value in Ksh"
                            name="valueInKsh"
                            value={formData.valueInKsh}
                            onChange={handleChange}
                            error={!!errors.valueInKsh}
                            helperText={errors.valueInKsh}
                            required
                          />
                        </Grid>
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.electronics}
                            onChange={handleChangeB}
                            name="electronics"
                          />
                        }
                        label="ELECTRONICS"
                      />
                      {formData.electronics && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Product serial"
                            name="serialNumber"
                            value={formData.serialNumber}
                            onChange={handleChange}
                            error={!!errors.serialNumber}
                            helperText={errors.serialNumber}
                            required
                          />
                        </Grid>
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.perishable}
                            onChange={handleChangeB}
                            name="perishable"
                          />
                        }
                        label="PERISHABLE"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.urgent}
                            onChange={handleChangeB}
                            name="urgent"
                          />
                        }
                        label="URGENT"
                      />
                    </FormGroup>
                  </Grid>
    
                {/* Payment Mode Selection */}
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required error={!!errors.modeOfPayment}>
                        <InputLabel id="payment-label">Mode of Payment</InputLabel>
                        <Select
                            labelId="payment-label"
                            name="modeOfPayment"
                            value={formData.modeOfPayment}
                            onChange={handleChange}
                            label="Mode of Payment"
                        >
                            {paymentOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.label} {/* Display the label */}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.modeOfPayment && <FormHelperText>{errors.modeOfPayment}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* Input Fields Based on Payment Method */}
                {formData.modeOfPayment === 'Mpesa' && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Enter Mpesa Number"
                                name="mpesaNumber"
                                value={formData.mpesaNumber}
                                onChange={handleChange}
                                error={!!errors.mpesaNumber}
                                helperText={errors.mpesaNumber}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Price"
                                name="mpesaAmount"
                                type="number"
                                value={formData.mpesaAmount}
                                onChange={handleChange}
                                error={!!errors.mpesaAmount}
                                helperText={errors.mpesaAmount}
                                fullWidth
                            />
                        </Grid>
                    </>
                )}

                {formData.modeOfPayment === 'Cash' && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Price"
                            name="cashAmount"
                            type="number"
                            value={formData.cashAmount}
                            onChange={handleChange}
                            error={!!errors.cashAmount}
                            helperText={errors.cashAmount}
                            fullWidth
                        />
                    </Grid>
                )}

                  {formData.modeOfPayment === 'cod' && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Price"
                            name="cashAmount"
                            type="number"
                            value={formData.cashAmount}
                            onChange={handleChange}
                            error={!!errors.cashAmount}
                            helperText={errors.cashAmount}
                            fullWidth
                        />
                    </Grid>
                )}

                {/* Handle Mixed Payment: Mpesa and Cash */}
                {formData.modeOfPayment === 'cash + mpesa' && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Enter Mpesa Number"
                                name="mpesaNumber"
                                value={formData.mpesaNumber}
                                onChange={handleChange}
                                error={!!errors.mpesaNumber}
                                helperText={errors.mpesaNumber}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Mpesa Amount"
                                name="mpesaAmount"
                                type="number"
                                value={formData.mpesaAmount}
                                onChange={handleChange}
                                error={!!errors.mpesaAmount}
                                helperText={errors.mpesaAmount}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Cash Amount"
                                name="cashAmount"
                                type="number"
                                value={formData.cashAmount}
                                onChange={handleChange}
                                error={!!errors.cashAmount}
                                helperText={errors.cashAmount}
                                fullWidth
                            />
                        </Grid>
                    </>
                )}

                  {/* Corporate Payment Option */}
                {formData.modeOfPayment === 'corporate' && (
                  <>
                  {/* Corporate Name Selection */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      label="Corporate Name"
                      name="corporateName"
                      value={formData.corporateName}
                      onChange={handleDropdownChange} // Handle the change
                      fullWidth
                      error={!!errors.corporateName} // Display error if exists
                      helperText={errors.corporateName} // Helper text for errors
                    >
                      {/* Render each corporate name as a dropdown option */}
                      {corporateSuggestions.length > 0 ? (
                        corporateSuggestions.map((option) => (
                          <MenuItem key={option.id} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled>
                          No options available
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>


                    {/* Cash Amount Field for Corporate Payment */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Cash Amount"
                        name="cashAmount"
                        type="number"
                        value={formData.cashAmount}
                        onChange={handleChangeC}
                        error={!!errors.cashAmount}
                        helperText={errors.cashAmount}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}

                {/* FOC Payment Fields */}
                {formData.modeOfPayment === 'foc' && (
                  <>
                    {/* OTP Input */}
                    {otpRequested && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Enter OTP"
                          name="otp"
                          value={formData.otp}
                          onChange={handleChange}
                          error={!!errors.otp}
                          helperText={errors.otp}
                          fullWidth
                        />
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={verifyOtp}
                        disabled={isLoading}
                        sx={{ mt: 1 }}
                      >
                        Verify OTP
                      </Button>
                    </Grid>
                  )}
              
                  {/* OTP Verification Status */}
                  {successMessage && <Typography color="green">{successMessage}</Typography>}
                  {errorMessage && <Typography color="red">{errorMessage}</Typography>}

                    <Grid item xs={12} sm={6}>
                            <TextField
                                label="Mpesa Amount"
                                name="mpesaAmount"
                                type="number"
                                value={formData.mpesaAmount}
                                onChange={handleChange}
                                error={!!errors.mpesaAmount}
                                helperText={errors.mpesaAmount}
                                fullWidth
                                disabled
                                style={{ backgroundColor: '#e0e0e0' }} // Grey background for disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Cash Amount"
                                name="cashAmount"
                                type="number"
                                value={formData.cashAmount}
                                onChange={handleChange}
                                error={!!errors.cashAmount}
                                helperText={errors.cashAmount}
                                fullWidth
                                disabled
                                style={{ backgroundColor: '#e0e0e0' }} // Grey background for disabled
                            />
                        </Grid>
                  </>
                )}
               
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Parcel Weight(Kg)"
                        name="parcelWeight"
                        type="number"
                        value={formData.parcelWeight}
                        onChange={handleChange}
                        error={!!errors.parcelWeight}
                        helperText={errors.parcelWeight}
                        required
                    />
                </Grid>
                  
                </Grid>
                <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: 2 }}
                disabled={isLoading || (formData.modeOfPayment === 'foc' && !otpVerified)} // Disable when OTP is not verified for FOC
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>

          </form>
          {isLoading && (
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                  <CircularProgress /> {/* Show a progress indicator */}
                  <Typography variant="body2">Submitting, please wait...</Typography>
              </Box>
          )}
              
            </>
            )}
          </Box>
        </Container>
      );
    };
    
    export default ParcelBooking;