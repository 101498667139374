import React, { useState,  useEffect } from 'react';
import { Drawer, Box, Typography, Button, Grid, MenuItem, Select, InputLabel, TextField, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../Admin/axiosInstance'; 
import './../../App.css'; 

const busRoutes = [
  { id: 1, name: 'Nairobi to Wajir', direction: 'outbound', route: [
      { stop: 'Nairobi', distance: 0 },
      { stop: 'Thika', distance: 45 },
      { stop: 'Matuu', distance: 62 },
      { stop: 'Kathiyoko', distance: 39 },
      { stop: 'Mwingi', distance: 30 },
      { stop: 'Nguni', distance: 33 },
      { stop: 'Ukasi', distance: 36 },
      { stop: 'Bisan hargeysa', distance: 20 },
      { stop: 'Bangal', distance: 30 },
      { stop: 'Madogo', distance: 73 },
      { stop: 'Garissa', distance: 6 },
      { stop: 'Shimbirey', distance: 59 },
      { stop: 'Dujis', distance: 32 },
      { stop: 'Madogashe', distance: 31 },
      { stop: 'Habaswein', distance: 62 },
      { stop: 'Qanchara', distance: 23 },
      { stop: 'Lag boqol', distance: 40 },
      { stop: 'Leheley', distance: 44 },
      { stop: 'Wajir', distance: 15 }
  ]},
  { id: 2, name: 'Wajir to Nairobi', direction: 'inbound', route: [
      { stop: 'Wajir', distance: 15 },
      { stop: 'Leheley', distance: 44 },
      { stop: 'Lag boqol', distance: 40 },
      { stop: 'Qanchara', distance: 23 },
      { stop: 'Habaswein', distance: 62 },
      { stop: 'Madogashe', distance: 31 },
      { stop: 'Dujis', distance: 32 },
      { stop: 'Shimbirey', distance: 59 },
      { stop: 'Garissa', distance: 6 },
      { stop: 'Madogo', distance: 73 },
      { stop: 'Bangal', distance: 30 },
      { stop: 'Bisan hargeysa', distance: 20 },
      { stop: 'Ukasi', distance: 36 },
      { stop: 'Nguni', distance: 33 },
      { stop: 'Mwingi', distance: 30 },
      { stop: 'Kathiyoko', distance: 39 },
      { stop: 'Matuu', distance: 62 },
      { stop: 'Thika', distance: 45 },
      { stop: 'Nairobi', distance: 0 }
  ]}
];

const allStops = Array.from(new Set(busRoutes.flatMap(route => route.route.map(stop => stop.stop))));

const Buses = ({ open, onClose }) => {
  const [searchData, setSearchData] = useState({ source: '', destination: '', date: '' });
  const [noBusesMessage, setNoBusesMessage] = useState('');
  const [minDate, setMinDate] = useState('')
  const navigate = useNavigate();


  // handle time search and date 
  useEffect(() => {
    // Get the current date and time
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();

    // Format the current date to YYYY-MM-DD
    const today = now.toISOString().split('T')[0];

    // If it's after 7:00 PM, set the minimum date to tomorrow
    if (currentHour > 19 || (currentHour === 19 && currentMinutes > 0)) {
      const tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);
      setMinDate(tomorrow.toISOString().split('T')[0]);
    } else {
      setMinDate(today); // Otherwise, allow today as the minimum date
    }
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData(prevState => ({ ...prevState, [name]: value }));
  };

  const calculatePrice = (source, destination, route) => {
    const pricePerKm = 4.41; 
    let totalDistance = 0;
    let sourceIndex = null;
    let destinationIndex = null;
  
    route.forEach((stop, index) => {
      if (stop.stop === source) sourceIndex = index;
      if (stop.stop === destination) destinationIndex = index;
    });
  
    if (sourceIndex !== null && destinationIndex !== null) {
      if (sourceIndex < destinationIndex) {
        // Calculate distance from source to destination
        for (let i = sourceIndex; i < destinationIndex; i++) {
          totalDistance += route[i + 1].distance;
        }
      } else {
        // Calculate distance from destination to source
        for (let i = destinationIndex; i < sourceIndex; i++) {
          totalDistance += route[i + 1].distance;
        }
      }
    }
  
    // Calculate the total price and round to the nearest 10
    const totalPrice = Math.round(totalDistance * pricePerKm / 10) * 10;
  
    return totalPrice;
  };  

  const handleSearch = async () => {
    const { source, destination, date } = searchData;
    let selectedBus = null;
    let selectedSegment = null;
    let price = null;
    let direction = null;
  
    // Check the outbound route (Nairobi -> Wajir)
    if (busRoutes[0].route.some(stop => stop.stop === source) && busRoutes[0].route.some(stop => stop.stop === destination)) {
      const sourceIndex = busRoutes[0].route.findIndex(stop => stop.stop === source);
      const destinationIndex = busRoutes[0].route.findIndex(stop => stop.stop === destination);
  
      selectedBus = busRoutes[0];
      selectedSegment = busRoutes[0].route.slice(Math.min(sourceIndex, destinationIndex), Math.max(sourceIndex, destinationIndex) + 1);
      price = calculatePrice(source, destination, busRoutes[0].route);
      direction = 1; // Set direction for outbound
    }
  
    // Check the inbound route (Wajir -> Nairobi)
    if (!selectedBus && busRoutes[1].route.some(stop => stop.stop === source) && busRoutes[1].route.some(stop => stop.stop === destination)) {
      const sourceIndex = busRoutes[1].route.findIndex(stop => stop.stop === source);
      const destinationIndex = busRoutes[1].route.findIndex(stop => stop.stop === destination);
  
      selectedBus = busRoutes[1];
      selectedSegment = busRoutes[1].route.slice(Math.min(sourceIndex, destinationIndex), Math.max(sourceIndex, destinationIndex) + 1);
      price = calculatePrice(source, destination, busRoutes[1].route);
      direction = 2; // Set direction for inbound
    }
  
    if (selectedBus && selectedSegment && price !== null) {
      // Rest of your logic...
    } else {
      setNoBusesMessage(`No available buses from ${source} to ${destination}`);
    };
  

    // Check the inbound route (Wajir -> Nairobi)
    if (!selectedBus && busRoutes[1].route.some(stop => stop.stop === source) && busRoutes[1].route.some(stop => stop.stop === destination)) {
        const sourceIndex = busRoutes[1].route.findIndex(stop => stop.stop === source);
        const destinationIndex = busRoutes[1].route.findIndex(stop => stop.stop === destination);

        if (sourceIndex < destinationIndex) {
            selectedBus = busRoutes[1];
            selectedSegment = busRoutes[1].route.slice(sourceIndex, destinationIndex + 1);
            price = calculatePrice(source, destination, busRoutes[1].route);
            direction = 2; 
        }
    }

    if (selectedBus && selectedSegment && price !== null) {
        try {
            // Fetch all schedules
            const scheduleResponse = await axiosInstance.get('/schedule/viewallschedules');
            const schedules = scheduleResponse.data || [];

            // Filter schedules by selected date and direction
            const matchingSchedule = schedules.find(schedule =>
                schedule.date === date && schedule.direction === direction
            );

            const busRegistration = matchingSchedule && matchingSchedule.busRegistration
                ? matchingSchedule.busRegistration
                : 'To Be Assigned'; 

            // Fetch booked seats
            const bookedResponse = await axiosInstance.get(`/reservation/bookedseats/${date}/${direction}`);
            const bookedSeats = bookedResponse.data || [];
            const bookedSeatsCount = bookedSeats.length;

            // Fetch reserved seats
            const reservedResponse = await axiosInstance.get(`/reservation/bookedReserves/${date}/${direction}`);
            const reservedSeats = reservedResponse.data || [];
            const reservedSeatsCount = reservedSeats.length;

            // Calculate available seats
            const totalSeats = 49; // Assuming the bus has 49 seats
            const availableSeats = totalSeats - bookedSeatsCount - reservedSeatsCount;

            const busDetails = {
                route: selectedBus.name,
                departure: '7:00 PM',
                reportTime: '6:00 AM',
                busRegistration: busRegistration,
                cost: price,
                booked: bookedSeatsCount,
                reserved: reservedSeatsCount,
                available: availableSeats
            };

            localStorage.setItem('busDetails', JSON.stringify(busDetails));
            navigate('/bus-details', { state: { source, destination, date, direction } }); 
            onClose(); // Close the drawer
        } catch (error) {
            console.error('Error fetching seat data:', error);
            setNoBusesMessage('Error fetching seat data. Please try again later.');
        }
    } else {
        setNoBusesMessage(`No available buses from ${source} to ${destination}`);
    }
};

return (
    <Box
      sx={{
        height: '70vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: '#f5f5f5', 
      }}
    >
      <Box
        sx={{
          maxWidth: 800,
          width: '100%', 
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          border: '1px solid #ccc',
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: '#fff', 
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Find Bus
        </Typography>
        <Grid container spacing={2}>
          {/* Source Input */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="source-label">Source</InputLabel>
              <Select
                labelId="source-label"
                label="Source"
                name="source"
                value={searchData.source}
                onChange={handleChange}
              >
                {allStops.map((stop, index) => (
                  <MenuItem key={index} value={stop}>
                    {stop}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Destination Input */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="destination-label">Destination</InputLabel>
              <Select
                labelId="destination-label"
                label="Destination"
                name="destination"
                value={searchData.destination}
                onChange={handleChange}
              >
                {allStops.map((stop, index) => (
                  <MenuItem key={index} value={stop}>
                    {stop}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Date Input */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Date"
              name="date"
              type="date"
              value={searchData.date}
              onChange={handleChange}
              InputProps={{
                inputProps: { min: minDate },
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
            />
          </Grid>
          {/* Error Message */}
          {noBusesMessage && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                {noBusesMessage}
              </Typography>
            </Grid>
          )}
          {/* Search Button */}
          <Grid item xs={12} md={6}>
            <Button variant="contained" color="primary" onClick={handleSearch} fullWidth>
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Buses;
