import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout'; 
import LandingPage from './components/LandingPage';
import SearchForm from './components/SearchForm';
import BusList from './components/BusList';
import AdminDashboard from './components/Admin/AdminDashboard';
import AdminPanel from './components/Admin/AdminPanel';
import LoginPage from './components/LoginPage';
import PasswordResetPage from './components/PasswordResetPage';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import BookingForm from './components/BookingForm';
import TicketPage from './components/TicketPage';
import TicketDetails from './components/TicketDetails';
import ParcelRegistrationForm from './components/Parcels/ParcelRegistrationForm';
import ReceiptPage from './components/Parcels/ReceiptPage';
import ParcelStatus from './components/Parcels/ParcelStatus';
import PrivateRoute from './components/Admin/PrivateRoute';
import BusDetails from './components/User/BusDetailsTable';
import CashForward from './components/User/CashForward';
import Dashboard from './components/User/Dashboard';
import UserManifest from './components/User/UserManifest';
import WaybillComponent from './components/User/WaybillComponent';
import SeatTransfer from './components/User/SeatTransfer';
import VoucherReceipt from './components/User/VoucherReceipt';
import DispatchParcel from './components/User/DispatchParcel';
import QrCodePage from './components/User/QrCodePage';
import ParcelBooking from './components/User/ParcelBooking';
import ReprintReceipt from './components/Parcels/ReprintReceipt';
import ParcelTable from './components/User/ParcelTable';
import ParcelList from './components/User/ParcelList';
import Corporate from './components/User/Corporate';
import BusDetailsTable from './components/User/BusDetailsTable';
import Buses from './components/Tickets/Buses';
// import BusForm from './components/User/BusForm';

import Home from './components/User/Home';


const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  // const [user, setUser] = useState({ username: localStorage.getItem('username'), role: localStorage.getItem('role'), userId: localStorage.getItem('userId') });
  const [user, setUser] = useState({
    username: localStorage.getItem('username'),
    role: localStorage.getItem('role'),
    userId: localStorage.getItem('userId'), 
  });
  

  useEffect(() => {
    console.log('Token updated:', token); 
  }, [token]);

  const handleSetToken = (newToken, role, userName, userId) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
    localStorage.setItem('role', role);
    localStorage.setItem('username', userName);
    localStorage.setItem('userId', userId);
    
    setUser({
      username: userName, 
      role: role,         
      userId: userId      
    });

    console.log('User set:', { userName, role, userId });
  };

  const handleLogout = () => {
    setToken('');
    setUser({ username: '', role: '', userId: '' });
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('role');
    localStorage.removeItem('username');
    localStorage.removeItem('userid');
    window.location.href = '/'; 
  };

  const isAuthenticated = !!token; 

  return (
    <Router>
    <Routes>
      <Route element={<Layout user={user} handleLogout={handleLogout} />}>
        <Route path="/login" element={<LoginPage setToken={handleSetToken} setUser={setUser} />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/search" element={<SearchForm />} />
        <Route path="/buses" element={<BusList />} />
        <Route path="/bus_form" element={<BusDetailsTable />} />
        <Route path="/bus" element={<Buses />} />
        <Route path="/reset-password" element={<PasswordResetPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/booking-form" element={<BookingForm />} />
        <Route path="/ticket" element={<TicketPage />} />
        <Route path="/ticket-status" element={<TicketDetails />} />
        <Route path="/parcel-status" element={<ParcelStatus />} />
        <Route path="/parcels" element={<ParcelRegistrationForm />} />
        <Route path="/receipt" element={<ReceiptPage />} />
        <Route path="/reprint-receipt/:parcelId" element={<ReprintReceipt />} />
        <Route path="/bus-details" element={<BusDetails />} />
        <Route path="/financial" element={<Dashboard />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/manifest" element={<UserManifest />} /> 
        <Route path="/waybill" element={<WaybillComponent />} /> 
        <Route path="/seat-transfer" element={<SeatTransfer />} />  
        <Route path="/voucher" element={<VoucherReceipt />} /> 
        <Route path="/cash-forward" element={<CashForward />} /> 
        <Route path="/dispatch" element={<DispatchParcel />} />
        <Route path="/ticket-details/:ticketNumber" element={<QrCodePage />} />
        <Route path="/parcel" element={<ParcelBooking />} />
        <Route path="/loading-list" element={<ParcelTable />} />
        <Route path="/parcel-list" element={<ParcelList />} />


        {/* <Route path="/home" element={<Home />} /> */}


  
        {/* Protect the AdminDashboard route */}
        <Route path="/admin/*" element={<PrivateRoute element={AdminDashboard} isAuthenticated={isAuthenticated} role={user.role} username={user.username} userId={user.userId} handleLogout={handleLogout} />} />

        <Route path="/home" element={<PrivateRoute element={Home} isAuthenticated={isAuthenticated} role={user.role} username={user.username} userId={user.userId} handleLogout={handleLogout}/>}/>
  
        {/* <Route path="/admin/login" element={<AdminLogin setToken={setToken} setUser={setUser} />} /> */}
      </Route>
    </Routes>
  </Router>
  
  );
};

export default App;
